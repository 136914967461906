import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getSetupByID, getUserLikesBySetup, addUserLike, removeUserLike } from "../../apiServices/setupApi";
import "./SetupByID.css";
import Loader from "../../components/Loader/Loader";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser"; 
import { loginRequest, b2cPolicies } from '../../authConfig';
import cars from "../../components/other/car"; 


function SetupByIDPage() {
  const { instance, inProgress } = useMsal();

 const handleLoginPopup = () => {
     instance
         .loginPopup({
             ...loginRequest,
             redirectUri: '/redirect',
         })
         .catch((error) => console.log(error));
 };



  const { id } = useParams();
  const [results, setResults] = useState([]);
  const [setup, setSetupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);
  const [hasLiked, setHasLiked] = useState(false); // Nouvel état pour savoir si l'utilisateur a liké
  const [isProcessing, setIsProcessing] = useState(false); // Pour gérer le chargement lors du like/unlike
  const [userAccount, setUserAccount] = useState(null);

  useEffect(() => {
    setUserAccount(instance.getActiveAccount());
  }, [instance]);

  useEffect(() => {
    const fetchSetupData = async () => {
        try {
            console.log("ID", id);
          const data = await getSetupByID(id);
          setSetupData(data[0]);

        // Vérifier si l'utilisateur a déjà liké ce setup
        if (userAccount) {
          const likes = await getUserLikesBySetup(id);
          console.log("Likes", likes);
          const userHasLiked = likes.some(like => like.Email === userAccount.username);
          console.log("User has liked:", userHasLiked);
          setHasLiked(userHasLiked);
        }

          /*console.log(data);
          console.log("Setup", setup);
          console.log("SetupData", setup.TeamNom);*/
          setLoading(false);
        } catch (err) {
            console.error("Error fetching setup:", error);
            setLoading(false);
        }
      };

      fetchSetupData();
  }, [id, userAccount]);


  const handleLikeSetup = async () => {
    if (!userAccount) {
      console.error("No active account found.");
      return;
    }

    setIsProcessing(true);

    try {
      if (hasLiked) {
        console.log("Removing like...");
        await removeUserLike(userAccount.username, id);
        setHasLiked(false);
      } else {
        console.log("Adding like...");
        await addUserLike(userAccount.username, id);
        setHasLiked(true);
      }
          // Recharger les détails du setup après avoir liké/déliké
    const updatedData = await getSetupByID(id);
    setSetupData(updatedData[0]);
    } catch (error) {
      console.error("Error processing like/unlike:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error">Error: Loading Setups</div>
    );
  }


  const parameterRanges = {
    FrontWing: { min: 0, max: 50 },
    RearWing: { min: 0, max: 50 },
    DifferentialAdjustmentOn: { min: 10, max: 100 },
    DifferentialAdjustmentOff: { min: 10, max: 100 },
    EngineBraking: { min: 0, max: 100 },
    FrontCamber: { min: -3.5, max: -2.5 },
    RearCamber: { min: -2.5, max: -0.7 },
    FrontToe: { min: 0, max: 0.5 },
    RearToe: { min: 0, max: 0.5 },
    FrontSuspension: { min: 1, max: 41 },
    RearSuspension: { min: 1, max: 41 },
    FrontAntiRollBar: { min: 1, max: 21 },
    RearAntiRollBar: { min: 1, max: 21 },
    FrontRideHeight: { min: 10, max: 40 },
    RearRideHeight: { min: 40, max: 100 },
    BreakPressure: { min: 80, max: 100 },
    FrontBreakBias: { min: 50, max: 70 },
    FrontRightTyrePressure: { min: 1, max: 71 },
    FrontLeftTyrePressure: { min: 1, max: 71 },
    RearRightTyrePressure: { min: 1, max: 61 },
    RearLeftTyrePressure: { min: 1, max: 61 },
  };

  const calculateFillPercentage = (value, min, max) => {
    return ((value - min) / (max - min)) * 100;
  };

  const renderParameter = (label, value, range) => {
    const { min, max } = range;
    const fillPercentage = calculateFillPercentage(value, min, max);

    return (
      <li className="setup-parameter">
        <span className="parameter-label">{label}:</span>
        <div className="parameter-bar">
          <div
            className="parameter-bar-fill"
            style={{ width: `${fillPercentage}%` }}
          ></div>
        </div>
        <span className="parameter-value">{value}</span>
      </li>
    );
  };

  return (
    <div className="results-container">
      <div className="setup-detail-container">
        <div className="setup-header">
          <h2>Setup Details</h2>

          {/* Affichage de l'image de la voiture */}
          {setup.TeamNom && (
            <div className="team-car-image">
              <img src={cars[setup.TeamNom]} alt={setup.TeamNom} />
            </div>
          )}

          <AuthenticatedTemplate>
            <div>
              <button
                onClick={handleLikeSetup}
                className="btn-like-setup"
                disabled={isProcessing}
              >
                {isProcessing
                  ? "Processing..."
                  : hasLiked
                  ? "Unlike Setup"
                  : "Like Setup"}
              </button>
            </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div>
              <button onClick={handleLoginPopup} className="btn-like-setup">
                Sign in for Like Setup
              </button>
            </div>
          </UnauthenticatedTemplate>
          <div className="setup-info">
            <p>
              <strong>User:</strong> {setup.UserEmail}
            </p>
            <p>
              <strong>GP:</strong> {setup.GPNom}
            </p>
            <p>
              <strong>Team:</strong> {setup.TeamNom}
            </p>
            <p>
              <strong>Controller:</strong> {setup.ControllerNom}
            </p>
            <p>
              <strong>Condition:</strong> {setup.ConditionNom}
            </p>
          </div>
        </div>

        <ul className="setup-parameters-list">
          {renderParameter(
            "Front Wing",
            setup.FrontWing,
            parameterRanges.FrontWing
          )}
          {renderParameter(
            "Rear Wing",
            setup.RearWing,
            parameterRanges.RearWing
          )}
          {renderParameter(
            "Differential Adjustment On",
            setup.DifferentialAdjustmentOn,
            parameterRanges.DifferentialAdjustmentOn
          )}
          {renderParameter(
            "Differential Adjustment Off",
            setup.DifferentialAdjustmentOff,
            parameterRanges.DifferentialAdjustmentOff
          )}
          {renderParameter(
            "Engine Braking",
            setup.EngineBraking,
            parameterRanges.EngineBraking
          )}
          {renderParameter(
            "Front Camber",
            setup.FrontCamber,
            parameterRanges.FrontCamber
          )}
          {renderParameter(
            "Rear Camber",
            setup.RearCamber,
            parameterRanges.RearCamber
          )}
          {renderParameter(
            "Front Toe",
            setup.FrontToe,
            parameterRanges.FrontToe
          )}
          {renderParameter("Rear Toe", setup.RearToe, parameterRanges.RearToe)}
          {renderParameter(
            "Front Suspension",
            setup.FrontSuspension,
            parameterRanges.FrontSuspension
          )}
          {renderParameter(
            "Rear Suspension",
            setup.RearSuspension,
            parameterRanges.RearSuspension
          )}
          {renderParameter(
            "Front Anti-Roll Bar",
            setup.FrontAntiRollBar,
            parameterRanges.FrontAntiRollBar
          )}
          {renderParameter(
            "Rear Anti-Roll Bar",
            setup.RearAntiRollBar,
            parameterRanges.RearAntiRollBar
          )}
          {renderParameter(
            "Front Ride Height",
            setup.FrontRideHeight,
            parameterRanges.FrontRideHeight
          )}
          {renderParameter(
            "Rear Ride Height",
            setup.RearRideHeight,
            parameterRanges.RearRideHeight
          )}
          {renderParameter(
            "Brake Pressure",
            setup.BreakPressure,
            parameterRanges.BreakPressure
          )}
          {renderParameter(
            "Front Brake Bias",
            setup.FrontBreakBias,
            parameterRanges.FrontBreakBias
          )}
          {renderParameter(
            "Front Right Tyre Pressure",
            setup.FrontRightTyrePressure,
            parameterRanges.FrontRightTyrePressure
          )}
          {renderParameter(
            "Front Left Tyre Pressure",
            setup.FrontLeftTyrePressure,
            parameterRanges.FrontLeftTyrePressure
          )}
          {renderParameter(
            "Rear Right Tyre Pressure",
            setup.RearRightTyrePressure,
            parameterRanges.RearRightTyrePressure
          )}
          {renderParameter(
            "Rear Left Tyre Pressure",
            setup.RearLeftTyrePressure,
            parameterRanges.RearLeftTyrePressure
          )}
          <li className="like-row">
            <span className="like-icon">❤️</span>
            <span className="like-text">{setup.Likes} Likes</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default SetupByIDPage;
