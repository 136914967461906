import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import logo from "./../../assets/images/f1logo.png";
import ThemeSwitch from "../other/ThemeSwitch";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser"; 
import { loginRequest } from '../../authConfig';
import { useNavigate } from "react-router-dom";

function NavBar() {
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();

  let activeAccount;

  if (instance) {
      activeAccount = instance.getActiveAccount();
  }

  const handleLoginPopup = () => {
     instance
         .loginPopup({
             ...loginRequest,
             redirectUri: '/redirect',
         })
         .catch((error) => console.log(error));
  };

  const handleLoginRedirect = () => {
     instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
     instance.logoutRedirect();
  };

  const handleLogoutPopup = () => {
     instance.logoutPopup({
         mainWindowRedirectUri: '/', // redirects the top level app after logout
     });
  };

  const handleProfileEdit = () => {
    if (activeAccount) {
      navigate(`/profile`); // Redirigez vers CreateSetup avec l'email
    } else {
      console.error("No active account found.");
    }
  };
 
  const [isOpen, setIsOpen] = useState(false);
  const savedTheme = localStorage.getItem("theme");
  const prefersDarkMode = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const initialTheme = savedTheme
    ? savedTheme
    : prefersDarkMode
    ? "dark"
    : "light";
  const [isDarkMode, setIsDarkMode] = useState(initialTheme === "dark");

  useEffect(() => {
    const themeToApply = isDarkMode ? "dark" : "light";
    document.body.setAttribute("data-theme", themeToApply);
    localStorage.setItem("theme", themeToApply);
  }, [isDarkMode]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const handleClick = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <div>
      <nav className="navbar_desk">
        <Link to={"/"}>
          <img className="logo" src={logo} alt="Logo"></img>
        </Link>
        <div className="buttons">
          <Link to={"/newsF1"}>
            <h1 className="NewsF1">F1 Insights</h1>
          </Link>
          <Link to={"/driverstandings"}>
            <h1 className="DriverStandings">Driver Standings</h1>
          </Link>
          <Link to={"/constructorstandings"}>
            <h1 className="ConstructorStandings">Constructor Standings</h1>
          </Link>
          <Link to={"/race"}>
            <h1 className="ConstructorStandings">Races</h1>
          </Link>
          <Link to={"/setup"}>
            <h1 className="ConstructorStandings">Setups</h1>
          </Link>
          <AuthenticatedTemplate>
              <div className="navbar-collapse justify-content-end">
                    <button onClick={handleProfileEdit} className="btn btn-info profileButton">
                        Profile
                    </button>
                    <button onClick={handleLogoutPopup} className="btn btn-info profileButton">
                        Log out
                    </button>
              </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
                <div className="navbar-collapse justify-content-end">
                <button onClick={handleLoginPopup} className="btn btn-info profileButton">
                    Sign in
                </button>
                    </div>
          </UnauthenticatedTemplate>
          <ThemeSwitch onChange={toggleTheme} checked={isDarkMode} />
        </div>
      </nav>
      <div className={`nav_items ${isOpen ? "open" : ""}`}>
        <ThemeSwitch
          className="switchMobile"
          onChange={toggleTheme}
          checked={isDarkMode}
          onClick={handleClick}
        />
        <Link to={"/"} onClick={handleClick}>
          <h1 className="home_mobile">Home</h1>
        </Link>
        <Link to={"/newsF1"} onClick={handleClick}>
          <h1 className="cs_mobile">F1 Insights</h1>
        </Link>
        <Link to={"/driverstandings"} onClick={handleClick}>
          <h1 className="ds_mobile">Driver Standings</h1>
        </Link>
        <Link to={"/constructorstandings"} onClick={handleClick}>
          <h1 className="cs_mobile">Constructor Standings</h1>
        </Link>
        <Link to={"/race"} onClick={handleClick}>
          <h1 className="cs_mobile">Race</h1>
        </Link>
        <Link to={"/setup"} onClick={handleClick}>
          <h1 className="cs_mobile">Setups</h1>
        </Link>
        <AuthenticatedTemplate>
              <div className="navbar-collapse justify-content-end">
                    <button onClick={handleProfileEdit} className="btn btn-info profileButton">
                        Edit Profile
                    </button>
                    <button onClick={handleLogoutPopup} className="btn btn-info profileButton">
                        Log out
                    </button>
              </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
                <div className="navbar-collapse justify-content-end">
                <button onClick={handleLoginPopup} className="btn btn-info profileButton">
                    Sign in
                </button>
                    </div>
          </UnauthenticatedTemplate>
      </div>
      <div className="mobileDiv">
        <div className={`nav_toggle ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="logo-container">
          <Link to={"/"}>
            <img
              className={`logo_toggle ${isOpen ? "open" : ""}`}
              src={logo}
              alt="Logo"
            ></img>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NavBar;