import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getSetupByUserEmail, deleteSetup } from "../../apiServices/setupApi";
import "./Profile.css";
import Loader from "../../components/Loader/Loader";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import PopUp from "../Popup/PopUp";
import ConfirmDeletePopup from "../DeletePopup/DeletePopup"; // Import du nouveau composant



function ProfilePage() {
  const [setupData, setSetupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const setupsPerPage = 10;
  const [popUpMessage, setPopUpMessage] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false); // État pour la popup de confirmation
  const [setupToDelete, setSetupToDelete] = useState(null);



  const { instance, inProgress } = useMsal();
  let activeAccount;

  if (instance) {
      activeAccount = instance.getActiveAccount();
  }
  const fetchSetupData = async () => {
    try {
      const email = activeAccount.username;
      const data = await getSetupByUserEmail(email);
      setSetupData(data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching setups:", err);
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSetupData();
  }, []);

  const handleDelete = async (id) => {
    setSetupToDelete(id);
    setShowConfirmDelete(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteSetup(setupToDelete);
      setPopUpMessage("Setup deleted successfully");
      fetchSetupData(); // Refresh setups after deletion
    } catch (err) {
      console.error("Error deleting setup:", err);
      setError(err);
    }
    setShowConfirmDelete(false);
  };

  const cancelDelete = () => {
    setShowConfirmDelete(false);
    setSetupToDelete(null);
  };

  const indexOfLastSetup = currentPage * setupsPerPage;
  const indexOfFirstSetup = indexOfLastSetup - setupsPerPage;
  const currentSetups = setupData.slice(indexOfFirstSetup, indexOfLastSetup);
  console.log(currentSetups);

  const nextPage = () => {
    if (currentPage < Math.ceil(setupData.length / setupsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }
  return (
    <div className="results-container">
      <h1>Your Setups</h1>
      <table className="results-table">
        <thead>
          <tr>
            <th>Team</th>
            <th>Condition</th>
            <th>GP</th>
            <th>Likes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentSetups.map((setup) => (
            <tr key={setup.ID[0]}>
              <td>{setup.TeamNom}</td>
              <td>{setup.ConditionNom}</td>
              <td>{setup.GPNom}</td>
              <td>{setup.Likes}</td>
              <td>
                <Link to={`/setup/edit/${setup.ID[0]}`} className="btn btn-edit">
                <i className="fas fa-edit"></i> Edit
                </Link>
                <button
                  onClick={() => handleDelete(setup.ID[0])}
                  className="btn btn-delete"
                >
                  <i className="fas fa-trash-alt"></i> Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous Page
        </button>
        <span className="current-page">Page {currentPage}</span>
        <button
          onClick={nextPage}
          disabled={currentPage === Math.ceil(setupData.length / setupsPerPage)}
        >
          Next Page
        </button>
      </div>
            {/* Popup for delete confirmation */}
            {showConfirmDelete && (
        <ConfirmDeletePopup
          message="Are you sure you want to delete this setup?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
            {/* Popup for delete confirmation */}
            {popUpMessage && (
        <PopUp
          message={popUpMessage}
          hasTimer={true}
          timerDuration={2000}
          onClose={() => setPopUpMessage("")}
        />
      )}
    </div>
  );
}

export default ProfilePage;
