import axios from "axios";

// URL for prod
const BASE_URL = "https://fonefocus-aqesa9f8hvhsc8bv.francecentral-01.azurewebsites.net/api";

// URL for dev
//const BASE_URL = "http://localhost:3333/api"; 

export const getSetupByGPName = async (gpName) => {
  try {
    console.log("gpName", gpName);
    const response = await axios.get(`${BASE_URL}/setup/gp/${gpName}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching setup data by gp:", error);
    throw error;
  }
};

export const getSetupByID = async (id) => {
    try {
      console.log("id", id);
      const response = await axios.get(`${BASE_URL}/setup/id/${id}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching setup data by id:", error);
      throw error;
    }
  };

export const saveSetup = async (setupData) => {
  try {
    const response = await axios.post(`${BASE_URL}/setup/save`, setupData);
    return response.data;
  } catch (error) {
    console.error("Error saving setup data:", error);
    throw error;
  }
};

export const fetchGPNames = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/gps`);
    return response.data;
  } catch (error) {
    console.error("Error fetching GP names:", error);
    throw error;
  }
};

export const getTeams = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/teams`);
    return response.data;
  } catch (error) {
    console.error("Error fetching teams:", error);
    throw error;
  }
};

export const getConditions = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/conditions`);
    return response.data;
  } catch (error) {
    console.error("Error fetching conditions:", error);
    throw error;
  }
};

export const getControllers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/controllers`);
    return response.data;
  } catch (error) {
    console.error("Error fetching controllers:", error);
    throw error;
  }
};

  export const getUserLikesBySetup = async (id) => {
    try {
      console.log("id", id);
      const response = await axios.get(`${BASE_URL}/user_like/like/${id}`);
      console.log("user like recup",response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching user like by Setup_id:", error);
      throw error;
    }
  };

  // Fonction pour ajouter un like
export const addUserLike = async (email, setupID) => {
  try {
    console.log("Adding like:", { email, setupID });
    const response = await axios.post(`${BASE_URL}/add-like`, { email, setupID });
    return response.data;
  } catch (error) {
    console.error("Error adding like:", error);
    throw error;
  }
};

// Fonction pour supprimer un like
export const removeUserLike = async (email, setupID) => {
  try {
    console.log("Removing like:", { email, setupID });
    const response = await axios.post(`${BASE_URL}/remove-like`, { email, setupID });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error removing like:", error);
    throw error;
  }
};

// Fonction pour obtenir les setups par email utilisateur
export const getSetupByUserEmail = async (email) => {
  try {
    console.log("Fetching setups for user email:", email);
    const response = await axios.get(`${BASE_URL}/profile/user/${email}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching setups by user email:", error);
    throw error;
  }
};

// Fonction pour modifier un setup existant
export const modifySetup = async (id, setupData) => {
  try {
    console.log("Modifying setup with ID:", id, setupData);
    const response = await axios.put(`${BASE_URL}/profile/modify/${id}`, setupData);
    return response.data;
  } catch (error) {
    console.error("Error modifying setup:", error);
    throw error;
  }
};

// Fonction pour supprimer un setup
export const deleteSetup = async (id) => {
  try {
    console.log("Deleting setup with ID:", id);
    const response = await axios.delete(`${BASE_URL}/profile/delete/${id}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleting setup:", error);
    throw error;
  }
};