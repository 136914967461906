import React, { useEffect } from "react";
import "./PopUp.css";

const PopUp = ({ message, onClose, hasX = false, hasTimer = true, timerDuration = 3000 }) => {
  useEffect(() => {
    if (hasTimer) {
      const timer = setTimeout(() => {
        onClose();
      }, timerDuration);
      
      return () => clearTimeout(timer);
    }
  }, [hasTimer, timerDuration, onClose]);

  if (!message) return null;

  return (
    <div className="popup-container">
      <div className="popup">
        <p>{message}</p>
        {hasX && (
          <button className="popup-x" onClick={onClose}>
            x
          </button>
        )}
      </div>
    </div>
  );
};

export default PopUp;
