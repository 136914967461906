import ALP from "../../assets/images/cars/Alpine.png";
import AST from "../../assets/images/cars/AstonMartin.png";
import FER from "../../assets/images/cars/Ferrari.png";
import HAS from "../../assets/images/cars/Haas.png";
import MCL from "../../assets/images/cars/Mclaren.png";
import MER from "../../assets/images/cars/Mercedes.png";
import RBC from "../../assets/images/cars/RBCA.png";
import RED from "../../assets/images/cars/RedBull.png";
import STA from "../../assets/images/cars/Stake.png";
import WIL from "../../assets/images/cars/Williams.png";


const cars = {
    Mercedes : MER,
    "Red Bull Racing" : RBC,
    Ferrari : FER,
    McLaren : MCL,
    Alpine : ALP,
    "Aston Martin" : AST,
    RedBull : RED,
    Sauber  : STA,
    Haas    : HAS,
    Williams    : WIL,  
  };
  
  export default cars;
  