import React from 'react';
import './DeletePopup.css';

const ConfirmDeletePopup = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="confirm-delete-popup">
            <div className="confirm-delete-popup-content">
                <p>{message}</p>
                <div className="confirm-delete-popup-buttons">
                    <button onClick={onConfirm} className="btn-confirm">Confirm</button>
                    <button onClick={onCancel} className="btn-cancel">Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDeletePopup;