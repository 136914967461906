import React from "react";
import "./CreateSetupCard.css";

const CreateSetupCard = ({ title="", children }) => {
  return (
    <aside className="widget card widget--sidebar widget_categories">
      <div className="widget__title card__header">
        <h4>{title}</h4>
      </div>
      <div className="widget__content card__content">{children}</div>
    </aside>
  );
};

export default CreateSetupCard;
