import React, { useEffect, useState } from "react";
import { Link, useNavigate  } from "react-router-dom";
import { getRaces } from "../../apiServices/racesApi";
import Flag from "react-world-flags";
import "./Setups.css";
import countryCode from "../../components/other/countryCode";
import Loader from "../../components/Loader/Loader";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser"; 
import { loginRequest, b2cPolicies } from '../../authConfig';
/*
import countryCode from "../other/countryCode";
import Loader from "../Loader/Loader";
*/

function SetupsPage() {
  const navigate = useNavigate(); // Utilisez useNavigate pour la navigation
  const { instance, inProgress } = useMsal();
  let activeAccount;

  if (instance) {
      activeAccount = instance.getActiveAccount();
  }

 const handleLoginPopup = () => {
     instance
         .loginPopup({
             ...loginRequest,
             redirectUri: '/redirect',
         })
         .catch((error) => console.log(error));
 };

 const handleCreateSetup = () => {
  if (activeAccount) {
    navigate(`/createSetup`); // Redirigez vers CreateSetup avec l'email
  } else {
    console.error("No active account found.");
  }
};
  const [races, setRaces] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortByLatest, setSortByLatest] = useState(false);

  useEffect(() => {
    const fetchRaces = async () => {
      try {
        const fetchedRaces = await getRaces();
        console.log(fetchedRaces);
        const currentDate = new Date();

        const pastRaces = fetchedRaces
          .sort((a, b) => new Date(a.date) - new Date(b.date));

        setRaces(pastRaces);
        setisLoading(false);
        document.title = "Races";
      } catch (error) {
        console.error("Error fetching races:", error);
        setError(error);
        setisLoading(false);
      }
    };

    fetchRaces();
  }, []);

  const toggleSortOrder = () => {
    setSortByLatest(!sortByLatest);
  };

  const sortedRaces = sortByLatest
    ? [...races].sort((a, b) => new Date(b.date) - new Date(a.date))
    : races;

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  return (
    <div className="races-container">
      <h1>F1 24 Setups</h1>
        <div className="race-info">
        <p>Find the best F1 24 game setups for each car and track of the F1 24 calendar to improve your lap times. Check out F1 24 car setups for both dry and wet conditions.</p>
        </div>
      <AuthenticatedTemplate>
              <div >
                    <button onClick={handleCreateSetup} className="btn-create-setup">
                        Create Setup
                    </button>
              </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
                <div>
                <button onClick={handleLoginPopup} className="btn-create-setup">
                    Sign in for Create Setup
                </button>
                    </div>
          </UnauthenticatedTemplate>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ul className="races-list">
            {sortedRaces.map((race, index) => (
              <li key={`${race.round}-${index}`} className="race-item">
                <Link to={`/setup/${race.Circuit.circuitId}/${race.raceName}`} className="race-link">
                  <div className="race-info">
                    <p className="race-name">{race.raceName}</p>
                    <p className="race-date">
                      {new Date(race.date).toLocaleDateString()}
                    </p>
                    <p className="race-location">
                      {race.Circuit.Location.locality},{" "}
                      {race.Circuit.Location.country}{" "}
                      <Flag
                        code={countryCode(race.Circuit.Location.country)}
                        className="race-flag"
                      />
                    </p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default SetupsPage;
