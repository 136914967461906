const countryCode = (country) => {
  const countryCodes = {
    "United States": "US",
    "UK": "GB",
    Italy: "IT",
    Spain: "ES",
    Bahrain: "BH",
    "Saudi Arabia" : "SA",
    Australia : "AU",
    Japan: "JP",
    China: "CN",
    USA: "US",
    Monaco : "MC",
    Canada: "CA",
    Hungary: "HU",
    Belgium: "BE",
    Netherlands: "NL",
    Azerbaijan: "AZ",
    Singapore: "SG",
    Mexico: "MX",
    Brazil: "BR",
    Qatar: "QA",
    UAE: "AE",
    Austria: "AT"

  };
  return countryCodes[country] || "";
};

export default countryCode;
