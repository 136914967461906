const teamColorClass = {
  Mercedes: "mercedes",
  "Red Bull": "redbull",
  Ferrari: "ferrari",
  "Aston Martin": "astonmartin",
  "Alpine F1 Team": "alpine",
  "RB F1 Team": "rb",
  Sauber: "sauber",
  McLaren: "mclaren",
  "Haas F1 Team": "haas",
  Williams: "williams",
};

export default teamColorClass;
