import React from "react";
import "./Schedule.css";
import "../../assets/global.css";

function Schedule() {
  return (
    <div>
      <div className="div_sch">
        <h1 className="sch">Next Races</h1>
      </div>
    </div>
  );
}

export default Schedule;
