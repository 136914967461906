import axios from "axios";

//url for prod
const driversA = "https://fonefocus-aqesa9f8hvhsc8bv.francecentral-01.azurewebsites.net/api/proxy/ergast/f1/2024/driverstandings/";

//url for dev
//const driversA = "http://localhost:3333/api/proxy/ergast/f1/2024/driverstandings/";

const driversService = {
  getDriverStandings() {
    return axios.get(driversA).then((res) => res.data);
  },

  getbyId(id) {
    return axios
      .get(`https://fonefocus-aqesa9f8hvhsc8bv.francecentral-01.azurewebsites.net/api/proxy/ergast/f1/2024/drivers/${id}.json`)
      .then((res) => res.data);
  },
};

export default driversService;
