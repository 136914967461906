import React, { useEffect, useState } from "react";
import "../../assets/global.css";
import "./NewsF1.css";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import Loader from "../../components/Loader/Loader";

function NewsF1() {
  const [isLoading, setIsLoading] = useState(true); // État pour gérer le chargement

  useEffect(() => {
    document.title = "News F1";

    // Simule la fin du chargement après 3 secondes
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer); // Nettoyage du timer
  }, []);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (favicon) {
      favicon.href = favicon;
    } else {
      const link = document.createElement("link");
      link.id = "favicon";
      link.rel = "icon";
      link.href = favicon;
      document.head.appendChild(link);
    }
  }, []);

  return (
    <div className="page-container">
      {isLoading && <Loader />} {/* Afficher le loader pendant le chargement */}
      <div className="twitter-feed-container" style={{ display: isLoading ? 'none' : 'block' }}>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="f1"
          options={{ height: "830px", width: "1000px" }}
        />
      </div>
    </div>
  );
}

export default NewsF1;