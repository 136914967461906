import { useParams, useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import CreateSetupCard from "../CreateSetupCard/CreateSetupCard";
import "../../assets/global.css";
import "./EditSetup.css";
import React, { useState, useEffect } from "react";
import {
  fetchGPNames,
  modifySetup,
  getSetupByID,
  getTeams,
  getConditions,
  getControllers,
} from "../../apiServices/setupApi";
import PopUp from "../Popup/PopUp";

function EditSetupPage() {
  const { id } = useParams(); // Get the setup ID from the URL parameters
  const { instance } = useMsal();
  let activeAccount = instance?.getActiveAccount();
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);
  const [frontLeftTyrePressure, setFrontLeftTyrePressure] = useState(24.2);
  const [frontRightTyrePressure, setFrontRightTyrePressure] = useState(24.2);
  const [rearLeftTyrePressure, setRearLeftTyrePressure] = useState(22);
  const [rearRightTyrePressure, setRearRightTyrePressure] = useState(22);
  const [diffOn, setdiffOn] = useState(50);
  const [diffOff, setdiffOff] = useState(50);
  const [engineBraking, setEngineBraking] = useState(50);

  const [frontCamber, setFrontCamber] = useState(-3.0);
  const [rearCamber, setRearCamber] = useState(-1.5);
  const [frontToe, setFrontToe] = useState(0.25);
  const [rearToe, setRearToe] = useState(0.25);

  const [frontSuspension, setFrontSuspension] = useState(21);
  const [rearSuspension, setRearSuspension] = useState(21);
  const [frontAntirollBar, setFrontAntirollBar] = useState(11);
  const [rearAntirollBar, setRearAntirollBar] = useState(11);
  const [frontRideHeight, setFrontRideHeight] = useState(25);
  const [rearRideHeight, setRearRideHeight] = useState(70);

  const [brakePressure, setBrakePressure] = useState(90);
  const [frontBrakeBias, setFrontBrakeBias] = useState(60);

  const [teamID, setTeamID] = useState(1);
  const [teams, setTeams] = useState([]);
  const [controllerID, setControllerID] = useState(1);
  const [controllers, setControllers] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [conditionID, setConditionID] = useState(1);
  const [gpList, setGpList] = useState([]);
  const [gpID, setGPID] = useState(1);
  const [frontWing, setFrontWing] = useState(25);
  const [rearWing, setRearWing] = useState(25);
  const [PopUpMessage, setPopUpMessage] = useState("");

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const setupDatas = await getSetupByID(id);
        if (setupDatas) {
            const setupData = setupDatas[0];
            console.log("Setup Data:", setupData.FrontLeftTyrePressure);
            setTeamID(setupData.TeamID || 1);
            setControllerID(setupData.ControllerID || 1);
            setConditionID(setupData.ConditionID || 1);
            setGPID(setupData.GPID || 1);
            setFrontWing(setupData.FrontWing ?? 25);
            setRearWing(setupData.RearWing ?? 25);
            setdiffOn(setupData.DifferentialAdjustmentOn ?? 50);
            setdiffOff(setupData.DifferentialAdjustmentOff ?? 50);
            setEngineBraking(setupData.EngineBraking ?? 50);
            setFrontCamber(setupData.FrontCamber ?? -3.0);
            setRearCamber(setupData.RearCamber ?? -1.5);
            setFrontToe(setupData.FrontToe ?? 0.25);
            setRearToe(setupData.RearToe ?? 0.25);
            setFrontSuspension(setupData.FrontSuspension ?? 21);
            setRearSuspension(setupData.RearSuspension ?? 21);
            setFrontAntirollBar(setupData.FrontAntiRollBar ?? 11);
            setRearAntirollBar(setupData.RearAntiRollBar ?? 11);
            setFrontRideHeight(setupData.FrontRideHeight ?? 25);
            setRearRideHeight(setupData.RearRideHeight ?? 70);
            setBrakePressure(setupData.BreakPressure ?? 90);
            setFrontBrakeBias(setupData.FrontBreakBias ?? 60);
            setFrontLeftTyrePressure(setupData.FrontLeftTyrePressure ?? 24.2);
            setFrontRightTyrePressure(setupData.FrontRightTyrePressure ?? 24.2);
            setRearLeftTyrePressure(setupData.RearLeftTyrePressure ?? 22);
            setRearRightTyrePressure(setupData.RearRightTyrePressure ?? 22);
        }
      } catch (error) {
        console.error("Error fetching setup data:", error);
        setPopUpMessage("Error fetching setup data. Please try again.");
      }
    };

    fetchInitialData();

    const fetchGPs = async () => {
      try {
        const gps = await fetchGPNames();
        setGpList(gps);
      } catch (error) {
        console.error("Error fetching GP names:", error);
        setPopUpMessage("Error fetching GP names. Please try again.");
      }
    };

    const fetchTeams = async () => {
      try {
        const teamsData = await getTeams();
        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    const fetchConditions = async () => {
      try {
        const data = await getConditions();
        setConditions(data);
      } catch (error) {
        console.error("Error fetching conditions:", error);
      }
    };

    const fetchControllers = async () => {
      try {
        const controllerData = await getControllers();
        setControllers(controllerData);
      } catch (error) {
        console.error("Error fetching controllers:", error);
      }
    };

    fetchGPs();
    fetchTeams();
    fetchConditions();
    fetchControllers();
  }, [id]);

  const handleSaveSetup = async () => {
    setIsSaving(true);
    const setupData = {
      TeamID: teamID,
      ControllerID: controllerID,
      ConditionID: conditionID,
      GPID: gpID,
      FrontWing: frontWing,
      RearWing: rearWing,
      DifferentialAdjustmentOn: diffOn,
      DifferentialAdjustmentOff: diffOff,
      EngineBraking: engineBraking,
      FrontCamber: frontCamber,
      RearCamber: rearCamber,
      FrontToe: frontToe,
      RearToe: rearToe,
      FrontSuspension: frontSuspension,
      RearSuspension: rearSuspension,
      FrontAntiRollBar: frontAntirollBar,
      RearAntiRollBar: rearAntirollBar,
      FrontRideHeight: frontRideHeight,
      RearRideHeight: rearRideHeight,
      BreakPressure: brakePressure,
      FrontBreakBias: frontBrakeBias,
      FrontRightTyrePressure: frontRightTyrePressure,
      FrontLeftTyrePressure: frontLeftTyrePressure,
      RearRightTyrePressure: rearRightTyrePressure,
      RearLeftTyrePressure: rearLeftTyrePressure,
      UserEmail: activeAccount?.username || "unknown@example.com",
    };

    try {
      console.log("Setup Data to be saved:", setupData);
      const response = await modifySetup(id, setupData);
      console.log("Setup modified successfully:", response);
      setPopUpMessage("Setup modified successfully");
      setTimeout(() => {
        navigate('/profile');
      }, 2000);
    } catch (error) {
      console.error("Error modifying setup:", error);
      setPopUpMessage("Error modifying setup. Please try again.");
      setIsSaving(false);
    }
  };


  return (
    <div className="create-setup-page">
      <h1>Edit Setup</h1>
      {/* <p>Creating a setup for: {activeAccount.username}</p> */}
      <div className="site-content">
        <div className="container">
          <form className="create-setup">
            {/* General Section */}
            <div className="row">
              <div className="col-12">
                <CreateSetupCard title="GENERAL">
                  <div className=" card__content">
                    <div className="options">
                      <div className="option">
                        <div className="form-group">
                          <label htmlFor="setupCircuit">GP Name</label>
                          <select
                            name="setupCircuit"
                            className="form-control"
                            value={gpID}
                            onChange={(e) => setGPID(Number(e.target.value))}
                          >
                            <option value="">Select GP Name</option>
                            {gpList.map((gp) => (
                              <option key={gp.ID} value={gp.ID}>
                                {gp.GPNom}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="option">
                        <div className="form-group">
                          <label htmlFor="setupTeam">Team</label>
                          <select
                            name="setupTeam"
                            className="form-control"
                            value={teamID}
                            onChange={(e) => setTeamID(Number(e.target.value))}
                          >
                            <option value="">Select Team</option>
                            {teams.map((team) => (
                              <option key={team.ID} value={team.ID}>
                                {team.TeamNom}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="option">
                        <div className="form-group">
                          <label htmlFor="setupWeather">Weather</label>
                          <select
                            name="setupCondition"
                            className="form-control"
                            value={conditionID}
                            onChange={(e) =>
                              setConditionID(Number(e.target.value))
                            }
                          >
                            <option value="">Select Condition</option>
                            {conditions.map((condition) => (
                              <option key={condition.ID} value={condition.ID}>
                                {condition.ConditionNom}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="option">
                        <div className="form-group">
                          <label htmlFor="setupController">Controller</label>
                          <select
                            name="setupController"
                            className="form-control"
                            value={controllerID}
                            onChange={(e) =>
                              setControllerID(Number(e.target.value))
                            }
                          >
                            <option value="">Select Controller</option>
                            {controllers.map((controller) => (
                              <option key={controller.ID} value={controller.ID}>
                                {controller.ControllerNom}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </CreateSetupCard>
              </div>
            </div>

            {/* AERODYNAMICS Section */}
            <div className="row">
              <div className="col-md-6">
                <CreateSetupCard title="AERODYNAMICS">
                  <div className="card__content">
                    {/* Front Aero Wing */}
                    <div className="form-group margin-row-20">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="frontWing">Front Aero Wing</label>
                        <div className="range-value-txt">
                          <span>{frontWing}</span>
                        </div>
                      </div>
                      <div className="range-wrap">
                        <input
                          type="range"
                          name="frontWing"
                          min="0"
                          max="50"
                          step="1"
                          value={frontWing}
                          onChange={(e) => setFrontWing(Number(e.target.value))}
                          className="form-control-range"
                        />
                        <div
                          className="range-value"
                          style={{
                            left: `calc(${(frontWing / 50) * 100}% - 10px)`,
                          }}
                        >
                          <span>{frontWing}</span>
                        </div>
                      </div>
                    </div>

                    {/* Rear Aero Wing */}
                    <div className="form-group margin-row-20">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="rearWing">Rear Aero Wing</label>
                        <div className="range-value-txt">
                          <span>{rearWing}</span>
                        </div>
                      </div>
                      <div className="range-wrap">
                        <input
                          type="range"
                          name="rearWing"
                          min="0"
                          max="50"
                          step="1"
                          value={rearWing}
                          onChange={(e) => setRearWing(Number(e.target.value))}
                          className="form-control-range"
                        />
                        <div
                          className="range-value"
                          style={{
                            left: `calc(${(rearWing / 50) * 100}% - 10px)`,
                          }}
                        >
                          <span>{rearWing}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </CreateSetupCard>
              </div>
            </div>

            {/* Transmission Section */}
            <div className="row">
              <div className="col-md-6">
                <CreateSetupCard title="TRANSMISSION">
                  {/* Differential On Throttle */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Differential On Throttle
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{diffOn}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((diffOn - 10) / (100 - 10)) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{diffOn}</span>
                      </div>
                      <input
                        type="range"
                        min="10"
                        max="100"
                        step="5"
                        value={diffOn}
                        onChange={(e) => setdiffOn(Number(e.target.value))}
                      />
                    </div>
                  </div>

                  {/* Differential Off Throttle */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Differential Off Throttle
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{diffOff}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((diffOff - 10) / (100 - 10)) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{diffOff}</span>
                      </div>
                      <input
                        type="range"
                        min="10"
                        max="100"
                        step="5"
                        value={diffOff}
                        onChange={(e) => setdiffOff(Number(e.target.value))}
                      />
                    </div>
                  </div>

                  {/* Engine Braking */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Engine Braking
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{engineBraking}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${(engineBraking / 100) * 100}% - 10px)`,
                        }}
                      >
                        <span>{engineBraking}</span>
                      </div>
                      <input
                        type="range"
                        min="0"
                        max="100"
                        step="10"
                        value={engineBraking}
                        onChange={(e) =>
                          setEngineBraking(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>
                </CreateSetupCard>
              </div>
            </div>

            {/* Suspension Geometry Section */}
            <div className="row">
              <div className="col-md-6">
                <CreateSetupCard title="SUSPENSION GEOMETRY">
                  {/* Front Camber */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Front Camber
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{frontCamber.toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((frontCamber + 3.5) / 1) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{frontCamber.toFixed(2)}</span>
                      </div>
                      <input
                        type="range"
                        min="-3.50"
                        max="-2.50"
                        step="0.10"
                        value={frontCamber}
                        onChange={(e) =>
                          setFrontCamber(parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>

                  {/* Rear Camber */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">Rear Camber</label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{rearCamber.toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((rearCamber + 2.2) / 1.5) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{rearCamber.toFixed(2)}</span>
                      </div>
                      <input
                        type="range"
                        min="-2.20"
                        max="-0.70"
                        step="0.10"
                        value={rearCamber}
                        onChange={(e) =>
                          setRearCamber(parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>

                  {/* Front Toe */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">Front Toe</label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{frontToe.toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${(frontToe / 0.5) * 100}% - 10px)`,
                        }}
                      >
                        <span>{frontToe.toFixed(2)}</span>
                      </div>
                      <input
                        type="range"
                        min="0.00"
                        max="0.50"
                        step="0.01"
                        value={frontToe}
                        onChange={(e) =>
                          setFrontToe(parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>

                  {/* Rear Toe */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">Rear Toe</label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{rearToe.toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${(rearToe / 0.5) * 100}% - 10px)`,
                        }}
                      >
                        <span>{rearToe.toFixed(2)}</span>
                      </div>
                      <input
                        type="range"
                        min="0.00"
                        max="0.50"
                        step="0.01"
                        value={rearToe}
                        onChange={(e) => setRearToe(parseFloat(e.target.value))}
                      />
                    </div>
                  </div>
                </CreateSetupCard>
              </div>
            </div>

            {/* Suspension Section */}
            <div className="row">
              <div className="col-md-6">
                <CreateSetupCard title="SUSPENSIONS">
                  {/* Front Suspension */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Front Suspension
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{frontSuspension}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((frontSuspension - 1) / (41 - 1)) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{frontSuspension}</span>
                      </div>
                      <input
                        type="range"
                        min="1"
                        max="41"
                        step="1"
                        value={frontSuspension}
                        onChange={(e) =>
                          setFrontSuspension(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>

                  {/* Rear Suspension */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Rear Suspension
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{rearSuspension}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((rearSuspension - 1) / (41 - 1)) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{rearSuspension}</span>
                      </div>
                      <input
                        type="range"
                        min="1"
                        max="41"
                        step="1"
                        value={rearSuspension}
                        onChange={(e) =>
                          setRearSuspension(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>

                  {/* Front Antiroll Bar */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Front Antiroll Bar
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{frontAntirollBar}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((frontAntirollBar - 1) / (21 - 1)) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{frontAntirollBar}</span>
                      </div>
                      <input
                        type="range"
                        min="1"
                        max="21"
                        step="1"
                        value={frontAntirollBar}
                        onChange={(e) =>
                          setFrontAntirollBar(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>

                  {/* Rear Antiroll Bar */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Rear Antiroll Bar
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{rearAntirollBar}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((rearAntirollBar - 1) / (21 - 1)) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{rearAntirollBar}</span>
                      </div>
                      <input
                        type="range"
                        min="1"
                        max="21"
                        step="1"
                        value={rearAntirollBar}
                        onChange={(e) =>
                          setRearAntirollBar(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>

                  {/* Front Ride Height */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Front Ride Height
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{frontRideHeight}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((frontRideHeight - 10) / (40 - 10)) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{frontRideHeight}</span>
                      </div>
                      <input
                        type="range"
                        min="10"
                        max="40"
                        step="1"
                        value={frontRideHeight}
                        onChange={(e) =>
                          setFrontRideHeight(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>

                  {/* Rear Ride Height */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Rear Ride Height
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{rearRideHeight}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((rearRideHeight - 40) / (100 - 40)) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{rearRideHeight}</span>
                      </div>
                      <input
                        type="range"
                        min="40"
                        max="100"
                        step="1"
                        value={rearRideHeight}
                        onChange={(e) =>
                          setRearRideHeight(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>
                </CreateSetupCard>
              </div>
            </div>

            {/* Brakes Section */}
            <div className="row">
              <div className="col-md-6">
                <CreateSetupCard title="BRAKES">
                  {/* Brake Pressure */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Brake Pressure
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{brakePressure}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((brakePressure - 80) / (100 - 80)) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{brakePressure}</span>
                      </div>
                      <input
                        type="range"
                        min="80"
                        max="100"
                        step="1"
                        value={brakePressure}
                        onChange={(e) =>
                          setBrakePressure(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>

                  {/* Front Brake Bias */}
                  <div className="form-group margin-row-20">
                    <div className="d-flex justify-content-between">
                      <div className="col-9">
                        <label className="range-value-label">
                          Front Brake Bias
                        </label>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="range-value-txt">
                          <span>{frontBrakeBias}</span>
                        </div>
                      </div>
                    </div>
                    <div className="range-wrap">
                      <div
                        className="range-value"
                        style={{
                          left: `calc(${
                            ((frontBrakeBias - 50) / (70 - 50)) * 100
                          }% - 10px)`,
                        }}
                      >
                        <span>{frontBrakeBias}</span>
                      </div>
                      <input
                        type="range"
                        min="50"
                        max="70"
                        step="1"
                        value={frontBrakeBias}
                        onChange={(e) =>
                          setFrontBrakeBias(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>
                </CreateSetupCard>
              </div>
            </div>

            {/* TYRES Section */}
            <div className="row">
              <div className="col-md-6">
                <CreateSetupCard title="TYRES">
                  <div className="card__content">
                    {/* Front Left Tyre Pressure */}

                    <div className="form-group margin-row-20">
                      <div className="col-12">
                        <div className="d-flex justify-content-between">
                          <div className="col-9">
                            <label className="range-value-label last">
                              Front Left Tyre Pressure
                            </label>
                          </div>
                          <div className="col-3 d-flex justify-content-end">
                            <div
                              id="setupFrontLeftTyrePressureLabel"
                              className="range-value-txt"
                            >
                              <span>{frontLeftTyrePressure}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="range-wrap">
                          <div
                            className="range-value"
                            style={{
                              left: `calc(${
                                ((frontLeftTyrePressure - 1) / (71 - 1)) * 100
                              }% - 10px)`,
                            }}
                          >
                            <span>{frontLeftTyrePressure}</span>
                          </div>
                          <input
                            id="setupFrontLeftTyrePressureRange"
                            type="range"
                            min="1"
                            max="71"
                            value={frontLeftTyrePressure}
                            step="1"
                            onChange={(e) =>
                              setFrontLeftTyrePressure(Number(e.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* Front Right Tyre Pressure */}

                    <div className="form-group margin-row-20">
                      <div className="col-12">
                        <div className="d-flex justify-content-between">
                          <div className="col-9">
                            <label className="range-value-label last">
                              Front Right Tyre Pressure
                            </label>
                          </div>
                          <div className="col-3 d-flex justify-content-end">
                            <div
                              id="setupFrontRightTyrePressureLabel"
                              className="range-value-txt"
                            >
                              <span>{frontRightTyrePressure}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="range-wrap">
                          <div
                            className="range-value"
                            style={{
                              left: `calc(${
                                ((frontRightTyrePressure - 1) / (71 - 1)) * 100
                              }% - 10px)`,
                            }}
                          >
                            <span>{frontRightTyrePressure}</span>
                          </div>
                          <input
                            id="setupFrontRightTyrePressureRange"
                            type="range"
                            min="1"
                            max="71"
                            value={frontRightTyrePressure}
                            step="0.1"
                            onChange={(e) =>
                              setFrontRightTyrePressure(Number(e.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* Rear Left Tyre Pressure */}

                    <div className="form-group margin-row-20">
                      <div className="col-12">
                        <div className="d-flex justify-content-between">
                          <div className="col-9">
                            <label className="range-value-label last">
                              Rear Left Tyre Pressure
                            </label>
                          </div>
                          <div className="col-3 d-flex justify-content-end">
                            <div
                              id="setupRearLeftTyrePressureLabel"
                              className="range-value-txt"
                            >
                              <span>{rearLeftTyrePressure}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="range-wrap">
                          <div
                            className="range-value"
                            style={{
                              left: `calc(${
                                ((rearLeftTyrePressure - 1) / (61 - 1)) * 100
                              }% - 10px)`,
                            }}
                          >
                            <span>{rearLeftTyrePressure}</span>
                          </div>
                          <input
                            id="setupRearLeftTyrePressureRange"
                            type="range"
                            min="1"
                            max="61"
                            value={rearLeftTyrePressure}
                            step="0.1"
                            onChange={(e) =>
                              setRearLeftTyrePressure(Number(e.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* Rear Right Tyre Pressure */}

                    <div className="form-group margin-row-20">
                      <div className="col-12">
                        <div className="d-flex justify-content-between">
                          <div className="col-9">
                            <label className="range-value-label last">
                              Rear Right Tyre Pressure
                            </label>
                          </div>
                          <div className="col-3 d-flex justify-content-end">
                            <div
                              id="setupRearRightTyrePressureLabel"
                              className="range-value-txt"
                            >
                              <span>{rearRightTyrePressure}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="range-wrap">
                          <div
                            className="range-value"
                            style={{
                              left: `calc(${
                                ((rearRightTyrePressure - 1) / (61 - 1)) * 100
                              }% - 10px)`,
                            }}
                          >
                            <span>{rearRightTyrePressure}</span>
                          </div>
                          <input
                            id="setupRearRightTyrePressureRange"
                            type="range"
                            min="1"
                            max="61"
                            value={rearRightTyrePressure}
                            step="1"
                            onChange={(e) =>
                              setRearRightTyrePressure(Number(e.target.value))
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="sub-button">
                      <div className="col-md-6 offset-md-3">
                        <div className="form-group form-group--submit">
                          <button
                            type="button"
                            className={`btn btn-lg btn-block ${isSaving ? 'btn-disabled' : 'btn-primary-inverse'}`}
                            onClick={handleSaveSetup}
                            disabled={isSaving} // Désactiver le bouton si isSaving est true
                          >
                            Save Setup
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CreateSetupCard>
              </div>
            </div>
          </form>
        </div>
      </div>

      <PopUp
        message={PopUpMessage}
        hasTimer={true}
        timerDuration={5000}
        onClose={() => {
          setPopUpMessage("");
          setIsSaving(false); // Réactiver le bouton lorsque la pop-up se ferme
        }}      />
    </div>
  );
}

export default EditSetupPage;
